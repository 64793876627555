body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.offcanvas {
  --bs-offcanvas-bg: rgb(5, 5, 5);
  --bs-offcanvas-color: #fff;
}

.map-custom-marker-label {
  color: #000;
  -webkit-text-fill-color: #ff0018;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: 1.1rem !important;
  background-color: #000000a1;
  padding: 3px;
  border-radius: 500px;
  padding-left: 10px;
  padding-right: 10px;
}
